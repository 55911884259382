export interface IAuthUser {
  email?: string;
  displayName?: string;
  role?: string;
}

export class AuthUser implements IAuthUser {
    constructor(
        public email?: string,
        public displayName?: string,
        public role?: string
    ) {

    }
}
