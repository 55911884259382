import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header.component';
import { DropdownModule } from 'app/shared/components/dropdown/dropdown.module';
import { ModalModule } from '../modal/modal.module';
import { NotificationsComponent } from './notifications/notifications.component';
import { TooltipModule } from '../tooltip/tooltip.module';

@NgModule({
  declarations: [HeaderComponent, NotificationsComponent],
  imports: [
    CommonModule,
    RouterModule,
    DropdownModule,
    ModalModule,
    TooltipModule,
  ],
  exports: [HeaderComponent, NotificationsComponent],
})
export class HeaderModule {}
