<header>
  <nav class="container">
    <div class="float-right">
      <label class="admin-panel" style="vertical-align: text-top;" *ngIf="(user$ | async)?.role === adminRole; else userPanel">
        <div class="admin-panel-inline">
          <a class="notifications-icon" (click)="openModal('notifications-modal')">
            <i class="fa fa-bell"></i>
            <label>{{ newNotifications.length }}</label>
          </a>
          <app-dropdown
            [options]="adminLinks"
            [placeholder]="placeholder"
            [selected]="selectedPage"
            (optSelect)="changePage($event)"
            className="hide-placehodler">
          </app-dropdown>
          <label>{{ (user$ | async)?.displayName }}</label>
        </div>
      </label>
      <ng-template #userPanel>
        <label class="admin-panel" style="vertical-align: text-top;">
          <div class="admin-panel-inline">
            <a class="notifications-icon" (click)="openModal('notifications-modal')">
              <i class="fa fa-bell"></i>
              <label>{{ newNotifications.length }}</label>
            </a>
            <app-dropdown [options]="userLinks" placeholder="Home " [selected]="selectedPage" (optSelect)="changePage($event)" className="hide-placehodler">
            </app-dropdown>
            <label>{{ (user$ | async)?.displayName }}</label>
          </div>
        </label>
      </ng-template>
      |
      <a class="link" (click)="onClickSignOut()">Log out</a>
    </div>

    <a class="header-link cursor-pointer" (click)="goHome()">
      :.: <br/>Rezonate.me
    </a>

    <div style="clear: both;"></div>
  </nav>
</header>

<app-modal id="notifications-modal">
  <div class="modal-header">
    Notifications
  </div>
  <div class="modal-body">
    <app-notifications [notifications]="newNotifications" [oldNotifications]="oldNotifications"></app-notifications>
  </div>
  <div class="modal-footer">
    <button (click)="closeModal('notifications-modal')">OK</button>
  </div>
</app-modal>
