import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SERVER_API_URL, SERVER_API_URL_V2 } from 'app/app.constants';
//   import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import {
  getItem,
  StorageItem
} from 'app/core/utils';
import { AuthService } from 'app/pages/auth/services/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor (private authService: AuthService) {}
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const isLoggedIn = true; //this.authService.isLoggedIn$;
    const token = getItem(StorageItem.Auth);

    const isApiUrl = request.url.startsWith(SERVER_API_URL) || request.url.startsWith(SERVER_API_URL_V2) || request.url.startsWith('http://116.203.112.172');

    if (isLoggedIn && isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return next.handle(request);
  }
}
