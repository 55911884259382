import { CommonModule } from "@angular/common";
import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { ROUTER_UTILS } from "app/core/utils/router.utils";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard, NoAuthGuard } from "app/core/guards";

import { LayoutModule } from "./components/layout/layout.module";
import { HeaderModule } from "./components/header/header.module";
import { NotFoundModule } from "./components/not-found/not-found.module";
import { NotFoundPage } from "./components/not-found/not-found.page";
import { DropdownModule } from "./components/dropdown/dropdown.module";
import { ModalModule } from "./components/modal/modal.module";
import { SettingsService } from "./services/settings.service";
import { NumericOnlyDirective } from "./directives/numberic-only.directive";
const APP_ROUTES: Routes = [
  {
    path: ROUTER_UTILS.config.auth.root,
    loadChildren: async () =>
      (await import("app/pages/auth/auth.module")).AuthModule,
    canLoad: [NoAuthGuard],
  },
  {
    path: ROUTER_UTILS.config.base.home,
    loadChildren: async () =>
      (await import("app/pages/home/home.module")).HomeModule,
  },
  {
    path: ROUTER_UTILS.config.admin.root,
    loadChildren: async () =>
      (await import("app/pages/admin/admin.module")).AdminModule,
    canLoad: [AuthGuard],
  },
  {
    path: ROUTER_UTILS.config.base.home,
    loadChildren: async () =>
      (await import("app/pages/pages.module")).PagesModule,
    canLoad: [AuthGuard],
  },
  // {
  //   path: ROUTER_UTILS.config.base.dashboard,
  //   loadChildren: async () =>
  //     (await import('@pages/dashboard/dashboard.module')).DashboardModule,
  //   canLoad: [AuthGuard],
  // },
  // {
  //   path: ROUTER_UTILS.config.settings.root,
  //   loadChildren: async () =>
  //     (await import('@pages/settings/settings.module')).SettingsModule,
  //   canLoad: [AuthGuard],
  // },
  // {
  //   path: ROUTER_UTILS.config.user.root,
  //   loadChildren: async () =>
  //     (await import('@pages/user/user.module')).UserModule,
  //   canLoad: [AuthGuard],
  // },
  {
    path: "**",
    loadChildren: async () =>
      (await import("app/shared/components/not-found/not-found.module"))
        .NotFoundModule,
    component: NotFoundPage,
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(APP_ROUTES),
    //   FooterModule,
    HeaderModule,
    LayoutModule,
    DropdownModule,
    NotFoundModule,
    ModalModule,
  ],
  exports: [
    RouterModule,
    //   FooterModule,
    HeaderModule,
    LayoutModule,
    DropdownModule,
    NotFoundModule,
    ModalModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [SettingsService],
  declarations: [
    NumericOnlyDirective
  ],
})
export class SharedModule {}
