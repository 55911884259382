import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTER_UTILS } from 'app/core/utils/router.utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from 'app/pages/auth/services/auth.service';
import { AuthUser, IAuthUser } from 'app/shared/models/auth-user';
import { getUser, StorageItem } from 'app/core/utils';
import { ADMIN_ROLE } from 'app/app.constants';
import { HomeService } from 'app/pages/home/services/home.service';
import { ModalService } from '../modal/modal.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  path = ROUTER_UTILS.config.base;
  adminLinks: string[] = [];
  userLinks: string[] = [];
  newNotifications: any;

  adminRole: string = ADMIN_ROLE;
  user$!: Observable<IAuthUser>;
  selectedPage: number = 0;
  placeholder: string = 'Home ';

  constructor(
    private router: Router,
    private authService: AuthService,
    private homeService: HomeService,
    private modalService: ModalService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.adminLinks = ['Home', 'Settings', 'Users', 'Target Groups', 'Starting TGs', 'Info fields', 'Prompts']
    this.userLinks = ['Home', 'Settings']
    this.user$ = this.authService.user$;

    if (window.location.pathname=== '/') {
      this.selectedPage = 0;
    } else if (window.location.pathname === '/settings') {
      this.selectedPage = 1;
    } else if (window.location.pathname === '/admin/users') {
      this.selectedPage = 2;
    } else if (window.location.pathname === '/admin/target_groups') {
      this.selectedPage = 3;
    } else if (window.location.pathname=== '/admin/starting_tgs') {
      this.selectedPage = 4;
    } else if (window.location.pathname === '/admin/explanations') {
      this.selectedPage = 5;
    } else if (window.location.pathname === '/admin/prompts') {
      this.selectedPage = 6;
    }
    this.placeholder = this.adminLinks[this.selectedPage];
    this.homeService.newNotifications$.subscribe((data: any) => {
      this.newNotifications = data;
      console.log(this.newNotifications)
    })
    // this.newNotifications = this.homeService.newNotifications;
  }

  getSelected(): void {
    return ;
  }

  onClickSignOut(): void {
    this.authService.signOut();
    const { root, signIn } = ROUTER_UTILS.config.auth;
    this.router.navigate(['/', root, signIn]);
  }

  goHome(): void {
    this.selectedPage = 0;
    this.router.navigate(['/']);
  }

  changePage(page: string) {
    const { root, users, targetGroups, restart, startingTgs, explanation } = ROUTER_UTILS.config.admin;

    switch (page) {
      case "Home":
        this.router.navigate(['/']);
        break;
      case "Settings":
        this.selectedPage = 1;
        this.router.navigate(['/', ROUTER_UTILS.config.base.settings]);
        break;
      case "Users":
        this.selectedPage = 2;
        this.router.navigate(['/', root, users]);
        break;
      case "Target Groups":
        this.selectedPage = 3;
        this.router.navigate(['/', root, targetGroups]);
        break;
      case "Starting TGs":
        this.selectedPage = 4;
        this.router.navigate(['/', root, startingTgs]);
        break;
      case "Info fields":
        this.selectedPage = 5;
        this.router.navigate(['/', root, explanation]);
        break;
      case "Prompts":
        this.selectedPage = 6;
        this.router.navigate(['/', root, 'prompts']);
        break;
      // case "Restart":
      //   this.router.navigate(['/', root, restart]);
      //   break;
    }
  }

  closeModal(modal: string): void {
    this.modalService.close(modal);
  }

  openModal(modal: string): void {
    this.modalService.open(modal);
  }
}
