<div>
  <a class="link" (click)="showOldNotifications = !showOldNotifications">{{ showOldNotifications ? 'Hide' : 'Show' }} Old Notifications</a>
  <div class="notification" *ngFor="let notification of notifications">
    <div class="left-part">
      <label class="state">{{ notification.state }}</label>
      <label class="status">{{ notification.status }}</label>
    </div>
    <div>
      <div *ngIf="notification.message">{{ notification.message }}</div>

      <div class="post" *ngFor="let post of notification.postReports">
        <div *ngIf="post.message">{{ post.message }}</div>
        <div class="social" *ngFor="let snReport of post.socialNetworkReports">
          <div *ngIf="snReport.message">
            <strong>{{ snReport.socialNetwork | titlecase }}:</strong>
            {{ snReport.message }}
          </div>
        </div>
      </div>
    </div>
    <div class="action-wrapper">
      <button class="action-button" (click)="onCheck(notification)" [tooltip]="'Mark as Read'">
        <i class="fa fa-eye-slash"></i>
      </button>
    </div>
  </div>
  <div *ngIf="showOldNotifications">
    <div class="notification other-notifications" *ngFor="let notification of oldNotifications">
      <div class="left-part">
        <label class="state">{{ notification.state }}</label>
        <label class="status">{{ notification.status }}</label>
      </div>
      <div>
        <div *ngIf="notification.message">{{ notification.message }}</div>

        <div class="post" *ngFor="let post of notification.postReports">
          <div *ngIf="post.message">{{ post.message }}</div>
          <div class="social" *ngFor="let snReport of post.socialNetworkReports">
            <div *ngIf="snReport.message">
              <strong>{{ snReport.socialNetwork | titlecase }}:</strong>
              {{ snReport.message }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
