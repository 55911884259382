export const ROUTER_UTILS = {
    config: {
      base: {
        home: '',
        summary: 'summary',
        settings: 'settings'
      },
      auth: {
        root: 'auth',
        signIn: 'login'
      },
      admin: {
        root: 'admin',
        targetGroups: 'target_groups',
        startingTgs: 'starting_tgs',
        users: 'users',
        restart: 'restart',
        explanation: 'informations',
        prompts: 'prompts'
      },
      user: {
        root: 'users',
        overview: 'overview',
        profile: ':username',
      },
      errorResponse: {
        notFound: '404',
      },
    },
  };
