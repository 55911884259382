<div class="dropdown-wrapper {{className}} {{isFocused ? 'focus':''}}"
    [ngClass]="{'is-open':isOpen, 'disabled':isReadOnly}" *ngIf="options" (contextmenu)="$event.stopPropagation();">
    <div class="box" (click)="toggle($event)">
        <ng-container>
            <div class="dropdown-selected" *ngIf="!multiple && isSelectedValue"><span>{{options[selected]}}</span>&nbsp;[▼]
            </div>
            <div class="dropdown-selected" *ngIf="!multiple && !isSelectedValue"><span>{{placeholder}}</span> [▼]</div>
            <div class="dropdown-selected" *ngIf="multiple"><span>{{ placeholder }}</span> [▼]</div>

        </ng-container>
    </div>
    <ul class="dropdown-options" *ngIf="options">

        <div
          class="search-options"
          [hidden]="!searchMode"
          (click)="$event.stopPropagation()"
          >
          <input
            type="text"
            placeholder="Type to search..."
            id="search-input"
            autocomplete="off"
            [(ngModel)]="searchValue"
            (keyup)="filterDropdown()" />
        </div>
        <li class="placeholder-li" *ngIf="placeholder && !multiple" (click)="$event.stopPropagation()">{{placeholder}}</li>
        <ng-container>
            <li id="li{{i}}" *ngFor="let option of filteredOptions; let i = index" [class.active]="!multiple && selected === i"
                [class.active-multiple]="multiple && multipleSelected.includes(i)"
                (click)="optionSelect(option, i, $event)">
                {{option}}
            </li>
        </ng-container>
        <li class="tgs-of-user-empty">Oooops this is empty :), create a new one</li>

    </ul>
</div>
