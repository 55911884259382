import { Component, OnInit } from '@angular/core';
import { AuthService } from './pages/auth/services/auth.service';
import { Observable } from 'rxjs';
import { SettingsService } from './shared/services/settings.service';
import { IAuthUser } from './shared/models/auth-user';
import { Router } from '@angular/router';
import { ROUTER_UTILS } from './core/utils/router.utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isLoggedIn$!: Observable<boolean>;

  constructor(
    private authService: AuthService,
    private settingsService: SettingsService,
    private router: Router
    ) {

  }

  ngOnInit(): void {
    this.isLoggedIn$ = this.authService.isLoggedIn$;
    // this.settingsService.getLicenses().subscribe(
    //   () => {},
    //   (error) => {
    //     const { root, signIn } = ROUTER_UTILS.config.auth;
    //     this.router.navigate(['/', root, signIn]);
    //   });
  }
}
