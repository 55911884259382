import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { ITargetGroup, TargetGroup } from 'app/shared/models/target-group'

import { SERVER_API_URL } from 'app/app.constants';
import { BehaviorSubject, Observable, throwError, of, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  public licenses: any[] | null = [];

  public languages: string[] | null = [];


  // languages$ = new BehaviorSubject<string[]>([]);
  // get languages(): string[] {
  //   return this.languages$.getValue();
  // }

  constructor(private http: HttpClient) {}

  getLicenses(): Observable<HttpResponse<any[]>> {
    return this.http
    .get<any[]>(SERVER_API_URL + 'licenses/', { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any[]>) => this.licenses = response.body),
      map((response: HttpResponse<any[]>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  getLanguages(): Observable<HttpResponse<any[]>> {
    return this.http
    .get<any[]>(SERVER_API_URL + 'languages/', { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any[]>) => {
        if (response.body) {
          this.languages = response.body.map((e) => e.name);
        } else {
          this.languages = ['Chinese', 'Dutch', 'English', 'French', 'German', 'Italian', 'Portuguese', 'Russian', 'Serbian', 'Spanish'];
        }
        return this.languages;
      }),
      map((response: HttpResponse<any[]>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  getAyrshareToken(): Observable<HttpResponse<any>> {
    return this.http
    .get<any>(SERVER_API_URL + 'settings/ayrshare-token', { observe: 'response' })
    .pipe(
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  getBrandDetails(): Observable<HttpResponse<any>> {
    return this.http
    .get<any>(SERVER_API_URL + 'brand-details', { observe: 'response' })
    .pipe(
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  updateBrandDetails(data: any): Observable<HttpResponse<any>> {
    return this.http.put<any>(SERVER_API_URL + 'brand-details', data, { observe: 'response'});
  }

  getSettings(): Observable<HttpResponse<any>> {
    return this.http
    .get<any>(SERVER_API_URL + 'settings', { observe: 'response' })
    .pipe(
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  updateSettings(data: any): Observable<HttpResponse<any>> {
    return this.http.put<any>(SERVER_API_URL + 'settings', data, { observe: 'response'});
  }

  getPagesList(): Observable<HttpResponse<any>> {
    return this.http
    .get<any>(SERVER_API_URL + 'page-content/list', { observe: 'response' })
    .pipe(
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  addPage(title: string): Observable<HttpResponse<any>> {
    return this.http.post<any>(SERVER_API_URL + 'page-content', {title: title}, { observe: 'response'});
  }

  addPageContent(pageId: number, data: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(SERVER_API_URL + 'page-content/' + pageId + '/sections', data, { observe: 'response'});
  }

  updatePageContent(sectionId: number, data: any): Observable<HttpResponse<any>> {
    return this.http.put<any>(SERVER_API_URL + 'page-content/sections/' + sectionId, data, { observe: 'response'});
  }

  getPromptTemplates(): Observable<HttpResponse<any>> {
    return this.http
    .get<any>(SERVER_API_URL + 'admin/prompt-templates', { observe: 'response' })
    .pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      }),
      catchError(this.handleError)
    );
  }

  addPrompt(data: any): Observable<HttpResponse<any>> {
    return this.http.put<any>(SERVER_API_URL + 'admin/api/v1/prompt-templates', data, { observe: 'response'});
  }

  editPrompt(templateId: number, data: any): Observable<HttpResponse<any>> {
    return this.http.put<any>(SERVER_API_URL + 'admin/api/v1/prompt-templates/' + templateId, data, { observe: 'response'});
  }

  deletePrompt(templateId: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(SERVER_API_URL + 'admin/api/v1/prompt-templates/' + templateId, { observe: 'response'});
  }

  private handleError(error: HttpErrorResponse) {
    console.error('server error:', error);

    if (!!error.error && !!error.error.message) {
      const errMessage = error.error.message;
      return throwError(errMessage);
    }
    return throwError(error || 'Server error');
  }
}
