import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'app/pages/auth/services/auth.service';
import { HomeService } from 'app/pages/home/services/home.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  @Input() notifications: any[] = [];

  constructor(
    private homeService: HomeService
  ) { }

  ngOnInit(): void {
  }

}
