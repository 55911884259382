import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AdminModule } from './admin/admin.module';
import { ROUTER_UTILS } from 'app/core/utils/router.utils';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, NoAuthGuard } from 'app/core/guards';
import { SettingsModule } from 'app/pages/settings/settings.module';
import { SharedModule } from 'app/shared/shared.module';
// import { ScheduleModule } from './home/schedule/schedule.module';

// import { TargetGroupComponent } from './target-group/target-group.component
// import { DropdownModule } from 'app/shared/components/dropdown/dropdown.module';
const APP_ROUTES: Routes = [
  // {
  //   path: ROUTER_UTILS.config.admin.targetGroups,
  //   loadChildren: async () =>
  //     (await import('app/pages/admin/admin.module')).AdminModule,
  //   canLoad: [AuthGuard],
  // },
  // {
  //   path: ROUTER_UTILS.config.base.settings,
  //   loadChildren: async () => (await import('app/pages/settings/settings.module')).SettingsModule,
  //   canLoad: []
  // }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    // RouterModule.forChild(APP_ROUTES),
    // AdminModule,
    SettingsModule,
    SharedModule,
    // ScheduleModule
  ],
  exports: [RouterModule,
    // AdminModule,
    SettingsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class PagesModule {}
