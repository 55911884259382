import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'app/pages/auth/services/auth.service';
import { HomeService } from 'app/pages/home/services/home.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  @Input() notifications: any[] = [];
  @Input() oldNotifications: any[] = [];
  showOldNotifications: boolean = false;

  constructor(
    private homeService: HomeService
  ) { }

  ngOnInit(): void {
  }

  onCheck(notification: any) {
    console.log(notification)
    this.homeService.updateNotification(notification.id, 'READ').subscribe((data: any) => {
      console.log(data)
      // move the notification to old notifications
      this.notifications = this.notifications.filter((n) => n.id !== notification.id);
      this.oldNotifications.unshift(notification);

    });
  }

}
