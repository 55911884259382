<div>
  <div class="notification" *ngFor="let notification of notifications">
    <div class="left-part">
      <label class="state">{{ notification.state }}</label>
      <label class="status">{{ notification.status }}</label>
    </div>
    <div>
      <div class="post" *ngFor="let post of notification.postReports">
        <div class="social" *ngFor="let snReport of post.socialNetworkReports">
          <strong>{{ snReport.socialNetwork }}</strong>
          {{ snReport.message }}
        </div>
      </div>
    </div>
  </div>
</div>
