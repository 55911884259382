import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// import { FooterModule } from '../footer/footer.module';
// import { HeaderModule } from '../header/header.module';
import { LayoutComponent } from './layout.component';
import { HeaderModule } from '../header/header.module';

@NgModule({
  declarations: [LayoutComponent],
  imports: [
    CommonModule,
    HeaderModule,
    //  HeaderModule, FooterModule
  ],
  exports: [LayoutComponent],
})
export class LayoutModule {}
